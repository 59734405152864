<template>
  <Dialog v-if="visible" v-model="visible" :options="dialogOptions.dialog">
    <TableLayout
      ref="tableLayout"
      :columns="columns"
      :query-format="queryFormat"
      :format-global-query="formatGlobalQuery"
      :url="`/${$businessISG}/monitor/deal/deal-page`"
    >
      <template slot="query-form" slot-scope="{ queryParam }">
        <QueryLayout lable="机构名称">
          <SelectTree
            v-model="queryParam.organizationUUID"
            :options="organizationTree"
            :replace-fields="{
              itemText: 'name',
              itemKey: 'uuid',
              itemChildren: 'children',
            }"
            placeholder="请选择机构名称"
          ></SelectTree>
        </QueryLayout>

        <QueryLayout lable="预警时间">
          <DatePicker v-model="queryParam.time" range></DatePicker>
        </QueryLayout>
        <QueryLayout lable="人员姓名">
          <BoxInput
            v-model="queryParam.customerName"
            placeholder="请输入人员姓名"
          ></BoxInput>
        </QueryLayout>
      </template>
      <div
        slot="table-item-action"
        slot-scope="{ row }"
        style="cursor: pointer"
        @click="$refs.EarlyWarningHandleInfoDialog.openDialog({ row })"
      >
        <div style="color: #25c4ff">查看</div>
      </div>
    </TableLayout>
    <EarlyWarningHandleInfoDialog
      ref="EarlyWarningHandleInfoDialog"
      prefix="deal"
      @success="$refs.tableLayout.getData()"
    ></EarlyWarningHandleInfoDialog>
  </Dialog>
</template>

<script>
import { mapState } from 'vuex';
import { assiginDeep } from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';
import TableLayout from '@/views/screen/components/TableLayout.vue';
import QueryLayout from '@/views/screen/components/QueryLayout.vue';
import SelectTree from '@/views/screen/components/SelectTree.vue';
import DatePicker from '@/views/screen/components/DatePicker.vue';
import BoxInput from '@/views/screen/components/BoxInput.vue';
import EarlyWarningHandleInfoDialog from '@/views/screen/earlyWarning/modules/EarlyWarningHandleInfoDialog.vue';
export default {
  //组件注册
  components: {
    Dialog,
    TableLayout,
    QueryLayout,
    SelectTree,
    BoxInput,
    DatePicker,
    EarlyWarningHandleInfoDialog,
  },
  //组件传值
  props: {},
  data() {
    return {
      visible: false,
      dialogOptions: {
        dialog: {
          width: 1500,
          title: '大额频繁交易预警记录',
        },
      },
      columns: [
        {
          text: '序号',
          value: 'sortNo',
          sortable: false,
          align: 'center',
        },
        {
          text: '机构名称',
          value: 'organizationText',
          sortable: false,
          align: 'center',
        },
        {
          text: '交易时间',
          value: 'startTime',
          sortable: false,
          align: 'center',
        },
        {
          text: '人员号',
          value: 'customerNo',
          sortable: false,
          align: 'center',
        },
        {
          text: '人员名称',
          value: 'tranCustomer',
          sortable: false,
          align: 'center',
        },
        {
          text: '交易金额',
          value: 'amount',
          sortable: false,
          align: 'center',
        },
        {
          text: '操作',
          value: 'action',
          sortable: false,
          align: 'center',
          itemSlots: true,
        },
      ],
    };
  },
  //计算属性
  computed: {
    ...mapState({
      organizationTree: (state) => state.screenStore.organizationTree,
      transactionMstList: (state) =>
        state.screenStore.analysisitem['transactionMstList']
          ? state.screenStore.analysisitem['transactionMstList']
          : [],
      reviewStatus: (state) =>
        state.screenStore.commondict['MonitorDataReviewStatusEnum']
          ? state.screenStore.commondict['MonitorDataReviewStatusEnum']
          : [],
    }),
  },
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    formatGlobalQuery(globalQuery) {
      const query = {
        organizationUUID: [globalQuery.organizationUUID],
        searchSpecialItems:'big_business'
      };
      if (globalQuery.timeStartStr && globalQuery.timeEndStr) {
        query.time = ['timeStartStr', 'timeEndStr'].map(
          (key) => globalQuery[key]
        );
      }
      return query;
    },
    queryFormat(query) {
      for (const key in query) {
        if (['organizationUUID'].includes(key) && Array.isArray(query[key])) {
          query[key] = query[key].toString();
        } else if (
          ['time'].includes(key) &&
          Array.isArray(query[key]) &&
          query[key].length == 2
        ) {
          Object.assign(
            query,
            ['StartStr', 'EndStr'].reduce(
              (val, suffix, i) => ({
                ...val,
                [`${key}${suffix}`]: query[key][i] ?? '',
              }),
              {}
            )
          );
          delete query[key];
        }
      }
      return query;
    },
    openDialog(options = {}) {
      this.dialogOptions = assiginDeep(this.dialogOptions, options);
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
