var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('Dialog',{attrs:{"options":_vm.dialogOptions.dialog},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('TableLayout',{attrs:{"columns":_vm.columns,"query-format":_vm.queryFormat,"format-global-query":_vm.formatGlobalQuery,"url":("/" + _vm.$businessISG + "/monitor/deal/deal-page")},scopedSlots:_vm._u([{key:"query-form",fn:function(ref){
var queryParam = ref.queryParam;
return [_c('QueryLayout',{attrs:{"lable":"机构名称"}},[_c('SelectTree',{attrs:{"options":_vm.organizationTree,"replace-fields":{
            itemText: 'name',
            itemKey: 'uuid',
            itemChildren: 'children',
          },"placeholder":"请选择机构名称"},model:{value:(queryParam.organizationUUID),callback:function ($$v) {_vm.$set(queryParam, "organizationUUID", $$v)},expression:"queryParam.organizationUUID"}})],1),_c('QueryLayout',{attrs:{"lable":"检测类型"}},[_c('Select',{attrs:{"options":_vm.dealAnalysisItemList,"replace-fields":{
            lable: 'itemName',
            value: 'uuid',
          },"placeholder":"请选择检测类型"},model:{value:(queryParam.searchAnalysisItems),callback:function ($$v) {_vm.$set(queryParam, "searchAnalysisItems", $$v)},expression:"queryParam.searchAnalysisItems"}})],1),_c('QueryLayout',{attrs:{"lable":"审核状态"}},[_c('Select',{attrs:{"options":_vm.violationFlag,"replace-fields":{
            lable: 'msg',
            value: 'code',
          },"placeholder":"请选择审核状态"},model:{value:(queryParam.violationFlag),callback:function ($$v) {_vm.$set(queryParam, "violationFlag", $$v)},expression:"queryParam.violationFlag"}})],1),_c('QueryLayout',{attrs:{"lable":"违规时间"}},[_c('DatePicker',{attrs:{"range":""},model:{value:(queryParam.time),callback:function ($$v) {_vm.$set(queryParam, "time", $$v)},expression:"queryParam.time"}})],1)]}},{key:"table-item-action",fn:function(ref){
          var row = ref.row;
return _c('div',{staticStyle:{"cursor":"pointer"}},[_c('div',{staticStyle:{"color":"#25c4ff"},on:{"click":function($event){return _vm.$refs.EarlyWarningHandleInfoDialog.openDialog({ row: row })}}},[_vm._v(" 查看 ")])])}}],null,false,3946756942)}),_c('EarlyWarningHandleInfoDialog',{ref:"EarlyWarningHandleInfoDialog",attrs:{"prefix":"deal"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }