var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('Dialog',{attrs:{"options":_vm.dialogOptions.dialog},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('TableLayout',{ref:"tableLayout",attrs:{"columns":_vm.columns,"query-format":_vm.queryFormat,"format-global-query":_vm.formatGlobalQuery,"url":("/" + _vm.$businessISG + "/monitor/deal/deal-page")},scopedSlots:_vm._u([{key:"query-form",fn:function(ref){
var queryParam = ref.queryParam;
return [_c('QueryLayout',{attrs:{"lable":"机构名称"}},[_c('SelectTree',{attrs:{"options":_vm.organizationTree,"replace-fields":{
            itemText: 'name',
            itemKey: 'uuid',
            itemChildren: 'children',
          },"placeholder":"请选择机构名称"},model:{value:(queryParam.organizationUUID),callback:function ($$v) {_vm.$set(queryParam, "organizationUUID", $$v)},expression:"queryParam.organizationUUID"}})],1),_c('QueryLayout',{attrs:{"lable":"预警时间"}},[_c('DatePicker',{attrs:{"range":""},model:{value:(queryParam.time),callback:function ($$v) {_vm.$set(queryParam, "time", $$v)},expression:"queryParam.time"}})],1),_c('QueryLayout',{attrs:{"lable":"人员姓名"}},[_c('BoxInput',{attrs:{"placeholder":"请输入人员姓名"},model:{value:(queryParam.customerName),callback:function ($$v) {_vm.$set(queryParam, "customerName", $$v)},expression:"queryParam.customerName"}})],1)]}},{key:"table-item-action",fn:function(ref){
          var row = ref.row;
return _c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$refs.EarlyWarningHandleInfoDialog.openDialog({ row: row })}}},[_c('div',{staticStyle:{"color":"#25c4ff"}},[_vm._v("查看")])])}}],null,false,2217643793)}),_c('EarlyWarningHandleInfoDialog',{ref:"EarlyWarningHandleInfoDialog",attrs:{"prefix":"deal"},on:{"success":function($event){return _vm.$refs.tableLayout.getData()}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }