<template>
  <div style="height: 100%">
    <div class="transactionRecheck">
      <div class="transactionRecheck_left">
        <EarlyWarningTotalLeft
          :key="refreshKey"
          :prefix="prefix"
          :global-query="globalQuery"
          style="margin-bottom: 29px"
        ></EarlyWarningTotalLeft>
        <EarlyWarningHistoryLeft
          :prefix="prefix"
          :refresh-key="refreshKey"
        ></EarlyWarningHistoryLeft>
      </div>
      <div class="transactionRecheck_center">
        <div class="img_center">
          <FloatBox class="even">
            <template slot="one-line">
              <template v-for="code of [4]">
                <template v-if="getWarningTotal(code)">
                  <div
                    v-for="(e, i) in [getWarningTotal(code)]"
                    :key="i + code"
                    class="line-box"
                    style="cursor: pointer"
                  >
                    <span>{{ e.text }}：</span>
                    <span class="cyan-value" @click="numberClick(e.id,1)">{{
                        formatString(e.totalCount)
                      }}</span>
                    &nbsp;/&nbsp;
                    <span class='red-value' @click="numberClick(e.id)">{{ formatString(e.count) }}</span>
                  </div>
                </template>
              </template>
            </template>
            <template slot="two-line">
              <template v-for="code of [2, 3]">
                <template v-if="getWarningTotal(code)">
                  <div
                    v-for="(e, i) in [getWarningTotal(code)]"
                    :key="i + code"
                    class="line-box"
                    style="cursor: pointer"

                  >
                    <span>{{ e.text }}：</span>
                    <span class="cyan-value" @click="numberClick(e.id,1)">{{
                        formatString(e.totalCount)
                      }}</span>
                    &nbsp;/&nbsp;
                    <span class='red-value' @click="numberClick(e.id)">{{ formatString(e.count) }}</span>
                  </div>
                </template>
              </template>
            </template>
            <template slot="three-line">
              <template v-for="code of [0,1]">
                <template v-if="getWarningTotal(code)">
                  <div
                    v-for="(e, i) in [getWarningTotal(code)]"
                    :key="i + code"
                    class="line-box"
                    style="cursor: pointer"

                  >
                    <span>{{ e.text }}：</span>
                    <span class="cyan-value" @click="numberClick(e.id,1)">{{
                        formatString(e.totalCount)
                      }}</span>
                    &nbsp;/&nbsp;
                    <span class='red-value' @click="numberClick(e.id)">{{ formatString(e.count) }}</span>
                  </div>
                </template>
              </template>
            </template>
          </FloatBox>
        </div>
        <EarlyWarningTrendFooter
          :prefix="prefix"
          :global-query="globalQuery"
          :refresh-key="refreshKey"
        ></EarlyWarningTrendFooter>
      </div>
      <div class="transactionRecheck_right">
        <EarlyWarningHandleRight
          :prefix="prefix"
          :global-query="globalQuery"
          :refresh-key="refreshKey"
          type='transactionRecheck'
        ></EarlyWarningHandleRight>
      </div>
    </div>
    <TransactionTableDialog
      ref="TransactionTableDialog"
    ></TransactionTableDialog>
    <ViolationTableDialog ref="ViolationTableDialog"></ViolationTableDialog>
    <AccountTableDialog ref="AccountTableDialog"></AccountTableDialog>
    <bigTransTableDialog ref='bigTransTableDialog'></bigTransTableDialog>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import {formatString} from '@/views/screen/config';
import TransactionTableDialog from './modules/TransactionTableDialog.vue';
import ViolationTableDialog from './modules/ViolationTableDialog.vue';
import AccountTableDialog from './modules/AccountTableDialog.vue';
import bigTransTableDialog from '@/views/screen/transactionRecheck/modules/bigTransTableDialog';

export default {
  //组件注册
  components: {
    EarlyWarningTotalLeft: () =>
      import(
        '@/views/screen/cashSupervision/modules/EarlyWarningTotalLeft.vue'
        ),
    EarlyWarningHistoryLeft: () =>
      import(
        '@/views/screen/cashSupervision/modules/EarlyWarningHistoryLeft.vue'
        ),
    FloatBox: () =>
      import('@/views/screen/cashSupervision/components/FloatBox.vue'),
    EarlyWarningTrendFooter: () =>
      import(
        '@/views/screen/cashSupervision/modules/EarlyWarningTrendFooter.vue'
        ),
    EarlyWarningHandleRight: () =>
      import(
        '@/views/screen/cashSupervision/modules/EarlyWarningHandleRight.vue'
        ),
    TransactionTableDialog,
    ViolationTableDialog,
    AccountTableDialog,
    bigTransTableDialog
  },
  //组件传值
  props: {
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      prefix: 'deal',
    };
  },
  //计算属性
  computed: {
    ...mapState({
      globalQuery: (state) => state.screenStore.globalQuery,
      warningTotal: (state) => state.screenStore.warningTotal,
    }),
    getWarningTotal() {
      return (index) => this.warningTotal.down && this.warningTotal.down[index];
    },
  },
  //属性监听
  watch: {
    globalQuery: {
      deep: true,
      handler: 'getMonitordata',
    },
    refreshKey: {
      handler: 'getMonitordata',
    },
  },
  //DOM访问
  mounted() {
    this.$bus.$on('TransactionTableDialog', (options) => {
      this.$refs.TransactionTableDialog &&
      this.$refs.TransactionTableDialog.openDialog(options);
    });
    this.$bus.$on('ViolationTableDialog', (options) => {
      this.$refs.ViolationTableDialog &&
      this.$refs.ViolationTableDialog.openDialog(options);
    });
    this.$bus.$on('AccountTableDialog', (options) => {
      this.$refs.AccountTableDialog &&
      this.$refs.AccountTableDialog.openDialog(options);
    });
    this.$bus.$on('bigTransTableDialog', (options) => {
      this.$refs.bigTransTableDialog &&
      this.$refs.bigTransTableDialog.openDialog(options);
    });
    this.$bus.$on(`warning-success-${this.prefix}`, this.getMonitordata);
    if (Object.keys(this.globalQuery).length) {
      this.getMonitordata();
    }
  },
  //保存方法
  methods: {
    numberClick(itemUuid, code) {
      const fixedParam = {
        // searchViolationItemCodeList: code,
        searchAnalysisItems: itemUuid || ''
      }
      if (!code) {
        // fixedParam.handleFlag = '02'
        fixedParam.violationFlag = '02'
      } else {
        // fixedParam.handleFlag = ''
        fixedParam.violationFlag = ''
      }

      // fixedParam.status = '02'
      this.$bus.$emit('ViolationTableDialog', {
        fixedParam
      })
    },
    formatString,
    ...mapMutations(['screenStore/SET_warningTotal']),
    async getMonitordata() {
      const data = await this.$http.get(
        `/${this.$businessISG}/monitor/${this.prefix}/violation-stat`,
        this.globalQuery
      );
      if (data.code == 200 && data.data)
        this['screenStore/SET_warningTotal'](data.data[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.transactionRecheck {
  display: flex;
  height: 100%;

  .transactionRecheck_left,
  .transactionRecheck_right {
    flex: 0 0 26%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .layoutBox {
      flex: 1;
      overflow: hidden;
    }
  }

  .transactionRecheck_center {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 38px;
    overflow: hidden;

    .img_center {
      flex: 1;
      overflow: hidden;
      background-image: url('../../../assets/screen/bj/transactionRecheck_center.gif');
      background-position: bottom;
    }

    .layoutBox {
      flex: 0 0 245px;
      overflow: hidden;
    }
  }
}

//::v-deep .float-box .one {
//  justify-content: space-between !important;
//}
</style>
